// source: flow/execution/execution.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var flow_entities_account_pb = require('../../flow/entities/account_pb.js');
goog.object.extend(proto, flow_entities_account_pb);
var flow_entities_event_pb = require('../../flow/entities/event_pb.js');
goog.object.extend(proto, flow_entities_event_pb);
goog.exportSymbol('proto.flow.execution.ExecuteScriptAtBlockIDRequest', null, global);
goog.exportSymbol('proto.flow.execution.ExecuteScriptAtBlockIDResponse', null, global);
goog.exportSymbol('proto.flow.execution.GetAccountAtBlockIDRequest', null, global);
goog.exportSymbol('proto.flow.execution.GetAccountAtBlockIDResponse', null, global);
goog.exportSymbol('proto.flow.execution.GetEventsForBlockIDsRequest', null, global);
goog.exportSymbol('proto.flow.execution.GetEventsForBlockIDsResponse', null, global);
goog.exportSymbol('proto.flow.execution.GetEventsForBlockIDsResponse.Result', null, global);
goog.exportSymbol('proto.flow.execution.GetTransactionResultRequest', null, global);
goog.exportSymbol('proto.flow.execution.GetTransactionResultResponse', null, global);
goog.exportSymbol('proto.flow.execution.PingRequest', null, global);
goog.exportSymbol('proto.flow.execution.PingResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.PingRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.execution.PingRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.PingRequest.displayName = 'proto.flow.execution.PingRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.PingResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.execution.PingResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.PingResponse.displayName = 'proto.flow.execution.PingResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetAccountAtBlockIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.execution.GetAccountAtBlockIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetAccountAtBlockIDRequest.displayName = 'proto.flow.execution.GetAccountAtBlockIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetAccountAtBlockIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.execution.GetAccountAtBlockIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetAccountAtBlockIDResponse.displayName = 'proto.flow.execution.GetAccountAtBlockIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.execution.ExecuteScriptAtBlockIDRequest.repeatedFields_, null);
};
goog.inherits(proto.flow.execution.ExecuteScriptAtBlockIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.ExecuteScriptAtBlockIDRequest.displayName = 'proto.flow.execution.ExecuteScriptAtBlockIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.execution.ExecuteScriptAtBlockIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.ExecuteScriptAtBlockIDResponse.displayName = 'proto.flow.execution.ExecuteScriptAtBlockIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetEventsForBlockIDsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.execution.GetEventsForBlockIDsResponse.repeatedFields_, null);
};
goog.inherits(proto.flow.execution.GetEventsForBlockIDsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetEventsForBlockIDsResponse.displayName = 'proto.flow.execution.GetEventsForBlockIDsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.execution.GetEventsForBlockIDsResponse.Result.repeatedFields_, null);
};
goog.inherits(proto.flow.execution.GetEventsForBlockIDsResponse.Result, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetEventsForBlockIDsResponse.Result.displayName = 'proto.flow.execution.GetEventsForBlockIDsResponse.Result';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetEventsForBlockIDsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.execution.GetEventsForBlockIDsRequest.repeatedFields_, null);
};
goog.inherits(proto.flow.execution.GetEventsForBlockIDsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetEventsForBlockIDsRequest.displayName = 'proto.flow.execution.GetEventsForBlockIDsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetTransactionResultRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.flow.execution.GetTransactionResultRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetTransactionResultRequest.displayName = 'proto.flow.execution.GetTransactionResultRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.flow.execution.GetTransactionResultResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.flow.execution.GetTransactionResultResponse.repeatedFields_, null);
};
goog.inherits(proto.flow.execution.GetTransactionResultResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.flow.execution.GetTransactionResultResponse.displayName = 'proto.flow.execution.GetTransactionResultResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.PingRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.PingRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.PingRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.PingRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.PingRequest}
 */
proto.flow.execution.PingRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.PingRequest;
  return proto.flow.execution.PingRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.PingRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.PingRequest}
 */
proto.flow.execution.PingRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.PingRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.PingRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.PingRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.PingRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.PingResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.PingResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.PingResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.PingResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.PingResponse}
 */
proto.flow.execution.PingResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.PingResponse;
  return proto.flow.execution.PingResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.PingResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.PingResponse}
 */
proto.flow.execution.PingResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.PingResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.PingResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.PingResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.PingResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetAccountAtBlockIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetAccountAtBlockIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetAccountAtBlockIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: msg.getBlockId_asB64(),
    address: msg.getAddress_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetAccountAtBlockIDRequest}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetAccountAtBlockIDRequest;
  return proto.flow.execution.GetAccountAtBlockIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetAccountAtBlockIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetAccountAtBlockIDRequest}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBlockId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setAddress(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetAccountAtBlockIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetAccountAtBlockIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetAccountAtBlockIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAddress_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes block_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.getBlockId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes block_id = 1;
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {string}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.getBlockId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBlockId()));
};


/**
 * optional bytes block_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {!Uint8Array}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.getBlockId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBlockId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.GetAccountAtBlockIDRequest} returns this
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.setBlockId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes address = 2;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.getAddress = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes address = 2;
 * This is a type-conversion wrapper around `getAddress()`
 * @return {string}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.getAddress_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getAddress()));
};


/**
 * optional bytes address = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getAddress()`
 * @return {!Uint8Array}
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.getAddress_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getAddress()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.GetAccountAtBlockIDRequest} returns this
 */
proto.flow.execution.GetAccountAtBlockIDRequest.prototype.setAddress = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetAccountAtBlockIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetAccountAtBlockIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetAccountAtBlockIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetAccountAtBlockIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && flow_entities_account_pb.Account.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetAccountAtBlockIDResponse}
 */
proto.flow.execution.GetAccountAtBlockIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetAccountAtBlockIDResponse;
  return proto.flow.execution.GetAccountAtBlockIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetAccountAtBlockIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetAccountAtBlockIDResponse}
 */
proto.flow.execution.GetAccountAtBlockIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new flow_entities_account_pb.Account;
      reader.readMessage(value,flow_entities_account_pb.Account.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetAccountAtBlockIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetAccountAtBlockIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetAccountAtBlockIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetAccountAtBlockIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      flow_entities_account_pb.Account.serializeBinaryToWriter
    );
  }
};


/**
 * optional flow.entities.Account account = 1;
 * @return {?proto.flow.entities.Account}
 */
proto.flow.execution.GetAccountAtBlockIDResponse.prototype.getAccount = function() {
  return /** @type{?proto.flow.entities.Account} */ (
    jspb.Message.getWrapperField(this, flow_entities_account_pb.Account, 1));
};


/**
 * @param {?proto.flow.entities.Account|undefined} value
 * @return {!proto.flow.execution.GetAccountAtBlockIDResponse} returns this
*/
proto.flow.execution.GetAccountAtBlockIDResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.flow.execution.GetAccountAtBlockIDResponse} returns this
 */
proto.flow.execution.GetAccountAtBlockIDResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.flow.execution.GetAccountAtBlockIDResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.ExecuteScriptAtBlockIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: msg.getBlockId_asB64(),
    script: msg.getScript_asB64(),
    argumentsList: msg.getArgumentsList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.ExecuteScriptAtBlockIDRequest;
  return proto.flow.execution.ExecuteScriptAtBlockIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBlockId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setScript(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addArguments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.ExecuteScriptAtBlockIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getScript_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getArgumentsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      3,
      f
    );
  }
};


/**
 * optional bytes block_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getBlockId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes block_id = 1;
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {string}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getBlockId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBlockId()));
};


/**
 * optional bytes block_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {!Uint8Array}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getBlockId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBlockId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} returns this
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.setBlockId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes script = 2;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getScript = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes script = 2;
 * This is a type-conversion wrapper around `getScript()`
 * @return {string}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getScript_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getScript()));
};


/**
 * optional bytes script = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getScript()`
 * @return {!Uint8Array}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getScript_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getScript()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} returns this
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.setScript = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * repeated bytes arguments = 3;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getArgumentsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * repeated bytes arguments = 3;
 * This is a type-conversion wrapper around `getArgumentsList()`
 * @return {!Array<string>}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getArgumentsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getArgumentsList()));
};


/**
 * repeated bytes arguments = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getArgumentsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.getArgumentsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getArgumentsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} returns this
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.setArgumentsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} returns this
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.addArguments = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDRequest} returns this
 */
proto.flow.execution.ExecuteScriptAtBlockIDRequest.prototype.clearArgumentsList = function() {
  return this.setArgumentsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.ExecuteScriptAtBlockIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.ExecuteScriptAtBlockIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: msg.getValue_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDResponse}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.ExecuteScriptAtBlockIDResponse;
  return proto.flow.execution.ExecuteScriptAtBlockIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.ExecuteScriptAtBlockIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDResponse}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.ExecuteScriptAtBlockIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.ExecuteScriptAtBlockIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
};


/**
 * optional bytes value = 1;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.prototype.getValue = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes value = 1;
 * This is a type-conversion wrapper around `getValue()`
 * @return {string}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.prototype.getValue_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getValue()));
};


/**
 * optional bytes value = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValue()`
 * @return {!Uint8Array}
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.prototype.getValue_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getValue()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.ExecuteScriptAtBlockIDResponse} returns this
 */
proto.flow.execution.ExecuteScriptAtBlockIDResponse.prototype.setValue = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.execution.GetEventsForBlockIDsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetEventsForBlockIDsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetEventsForBlockIDsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    resultsList: jspb.Message.toObjectList(msg.getResultsList(),
    proto.flow.execution.GetEventsForBlockIDsResponse.Result.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetEventsForBlockIDsResponse;
  return proto.flow.execution.GetEventsForBlockIDsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.flow.execution.GetEventsForBlockIDsResponse.Result;
      reader.readMessage(value,proto.flow.execution.GetEventsForBlockIDsResponse.Result.deserializeBinaryFromReader);
      msg.addResults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetEventsForBlockIDsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetEventsForBlockIDsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResultsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.flow.execution.GetEventsForBlockIDsResponse.Result.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetEventsForBlockIDsResponse.Result.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: msg.getBlockId_asB64(),
    blockHeight: jspb.Message.getFieldWithDefault(msg, 2, 0),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    flow_entities_event_pb.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetEventsForBlockIDsResponse.Result;
  return proto.flow.execution.GetEventsForBlockIDsResponse.Result.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBlockId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setBlockHeight(value);
      break;
    case 3:
      var value = new flow_entities_event_pb.Event;
      reader.readMessage(value,flow_entities_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetEventsForBlockIDsResponse.Result.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getBlockHeight();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      flow_entities_event_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional bytes block_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.getBlockId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes block_id = 1;
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {string}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.getBlockId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBlockId()));
};


/**
 * optional bytes block_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {!Uint8Array}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.getBlockId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBlockId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} returns this
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.setBlockId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional uint64 block_height = 2;
 * @return {number}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.getBlockHeight = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} returns this
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.setBlockHeight = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated flow.entities.Event events = 3;
 * @return {!Array<!proto.flow.entities.Event>}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.flow.entities.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, flow_entities_event_pb.Event, 3));
};


/**
 * @param {!Array<!proto.flow.entities.Event>} value
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} returns this
*/
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.flow.entities.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.entities.Event}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.flow.entities.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result} returns this
 */
proto.flow.execution.GetEventsForBlockIDsResponse.Result.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


/**
 * repeated Result results = 1;
 * @return {!Array<!proto.flow.execution.GetEventsForBlockIDsResponse.Result>}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.prototype.getResultsList = function() {
  return /** @type{!Array<!proto.flow.execution.GetEventsForBlockIDsResponse.Result>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.flow.execution.GetEventsForBlockIDsResponse.Result, 1));
};


/**
 * @param {!Array<!proto.flow.execution.GetEventsForBlockIDsResponse.Result>} value
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse} returns this
*/
proto.flow.execution.GetEventsForBlockIDsResponse.prototype.setResultsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.flow.execution.GetEventsForBlockIDsResponse.Result=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse.Result}
 */
proto.flow.execution.GetEventsForBlockIDsResponse.prototype.addResults = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.flow.execution.GetEventsForBlockIDsResponse.Result, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.execution.GetEventsForBlockIDsResponse} returns this
 */
proto.flow.execution.GetEventsForBlockIDsResponse.prototype.clearResultsList = function() {
  return this.setResultsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.execution.GetEventsForBlockIDsRequest.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetEventsForBlockIDsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetEventsForBlockIDsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetEventsForBlockIDsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    blockIdsList: msg.getBlockIdsList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetEventsForBlockIDsRequest}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetEventsForBlockIDsRequest;
  return proto.flow.execution.GetEventsForBlockIDsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetEventsForBlockIDsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetEventsForBlockIDsRequest}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addBlockIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetEventsForBlockIDsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetEventsForBlockIDsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetEventsForBlockIDsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBlockIdsList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      2,
      f
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.execution.GetEventsForBlockIDsRequest} returns this
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated bytes block_ids = 2;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.getBlockIdsList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * repeated bytes block_ids = 2;
 * This is a type-conversion wrapper around `getBlockIdsList()`
 * @return {!Array<string>}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.getBlockIdsList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getBlockIdsList()));
};


/**
 * repeated bytes block_ids = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockIdsList()`
 * @return {!Array<!Uint8Array>}
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.getBlockIdsList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getBlockIdsList()));
};


/**
 * @param {!(Array<!Uint8Array>|Array<string>)} value
 * @return {!proto.flow.execution.GetEventsForBlockIDsRequest} returns this
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.setBlockIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 * @return {!proto.flow.execution.GetEventsForBlockIDsRequest} returns this
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.addBlockIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.execution.GetEventsForBlockIDsRequest} returns this
 */
proto.flow.execution.GetEventsForBlockIDsRequest.prototype.clearBlockIdsList = function() {
  return this.setBlockIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetTransactionResultRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetTransactionResultRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetTransactionResultRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    blockId: msg.getBlockId_asB64(),
    transactionId: msg.getTransactionId_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetTransactionResultRequest}
 */
proto.flow.execution.GetTransactionResultRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetTransactionResultRequest;
  return proto.flow.execution.GetTransactionResultRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetTransactionResultRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetTransactionResultRequest}
 */
proto.flow.execution.GetTransactionResultRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setBlockId(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetTransactionResultRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetTransactionResultRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetTransactionResultRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBlockId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getTransactionId_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional bytes block_id = 1;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.getBlockId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes block_id = 1;
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {string}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.getBlockId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getBlockId()));
};


/**
 * optional bytes block_id = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getBlockId()`
 * @return {!Uint8Array}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.getBlockId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getBlockId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.GetTransactionResultRequest} returns this
 */
proto.flow.execution.GetTransactionResultRequest.prototype.setBlockId = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bytes transaction_id = 2;
 * @return {!(string|Uint8Array)}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.getTransactionId = function() {
  return /** @type {!(string|Uint8Array)} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes transaction_id = 2;
 * This is a type-conversion wrapper around `getTransactionId()`
 * @return {string}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.getTransactionId_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getTransactionId()));
};


/**
 * optional bytes transaction_id = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getTransactionId()`
 * @return {!Uint8Array}
 */
proto.flow.execution.GetTransactionResultRequest.prototype.getTransactionId_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getTransactionId()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.flow.execution.GetTransactionResultRequest} returns this
 */
proto.flow.execution.GetTransactionResultRequest.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.flow.execution.GetTransactionResultResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.flow.execution.GetTransactionResultResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.flow.execution.GetTransactionResultResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.flow.execution.GetTransactionResultResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetTransactionResultResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusCode: jspb.Message.getFieldWithDefault(msg, 1, 0),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    flow_entities_event_pb.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.flow.execution.GetTransactionResultResponse}
 */
proto.flow.execution.GetTransactionResultResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.flow.execution.GetTransactionResultResponse;
  return proto.flow.execution.GetTransactionResultResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.flow.execution.GetTransactionResultResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.flow.execution.GetTransactionResultResponse}
 */
proto.flow.execution.GetTransactionResultResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStatusCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 3:
      var value = new flow_entities_event_pb.Event;
      reader.readMessage(value,flow_entities_event_pb.Event.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.flow.execution.GetTransactionResultResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.flow.execution.GetTransactionResultResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.flow.execution.GetTransactionResultResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.flow.execution.GetTransactionResultResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusCode();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      flow_entities_event_pb.Event.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 status_code = 1;
 * @return {number}
 */
proto.flow.execution.GetTransactionResultResponse.prototype.getStatusCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.flow.execution.GetTransactionResultResponse} returns this
 */
proto.flow.execution.GetTransactionResultResponse.prototype.setStatusCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.flow.execution.GetTransactionResultResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.flow.execution.GetTransactionResultResponse} returns this
 */
proto.flow.execution.GetTransactionResultResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated flow.entities.Event events = 3;
 * @return {!Array<!proto.flow.entities.Event>}
 */
proto.flow.execution.GetTransactionResultResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.flow.entities.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, flow_entities_event_pb.Event, 3));
};


/**
 * @param {!Array<!proto.flow.entities.Event>} value
 * @return {!proto.flow.execution.GetTransactionResultResponse} returns this
*/
proto.flow.execution.GetTransactionResultResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.flow.entities.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.flow.entities.Event}
 */
proto.flow.execution.GetTransactionResultResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.flow.entities.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.flow.execution.GetTransactionResultResponse} returns this
 */
proto.flow.execution.GetTransactionResultResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};


goog.object.extend(exports, proto.flow.execution);
